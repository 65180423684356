




















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { WorkOrderFlowEntityModel, WorkOrderFlowQueryModel } from '@common-src/entity-model/work-order-flow-entity';
import WorkOrderFlowService from '@common-src/service/work-order-flow';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class WorkOrderFlowListComponent extends TableDialogFormComponent<WorkOrderFlowEntityModel, WorkOrderFlowQueryModel> {
    WorkOrderFlowEntityModel = WorkOrderFlowEntityModel;

    // 流程图参数
    flowVisible: boolean = false;

    flowSVG: any = '';

    flowWidth: number = 1200;

    created() {
        this.initTable({
            service: WorkOrderFlowService,
            queryModel: new WorkOrderFlowQueryModel(),
            tableColumns: WorkOrderFlowEntityModel.getTableColumns()
        });

        this.getList();
    }

    submitForm() {
        let params = this.getNewData(this.formDialog.jtlForm.formModel.processKey, this.formDialog.jtlForm.formModel.processName);

        if (this.formDialog.viewMode === ViewModeType.NEW) {
            (this.service as any).saveXML(params).then(res => {
                this.getList();
            });
        } else {
            this.getList();
        }
    }

    getNewData(id, name) {
        let formData = new FormData();

        let newXml = '<?xml version="1.0" encoding="UTF-8"?>\n' +
               '<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:activiti="http://activiti.org/bpmn" targetNamespace="http://www.activiti.org/processdef">\n' +
               `<process id="${id}" name="${name}" isExecutable="true">\n` +
               '    <extensionElements>\n' +
               '    <activiti:executionListener event="end">\n' +
               '        <activiti:field name="userVariableName">\n' +
               '        <activiti:string>user_creator</activiti:string>\n' +
               '        </activiti:field>\n' +
               '    </activiti:executionListener>\n' +
               '    </extensionElements>\n' +
               '    <startEvent id="start" name="开始">\n' +
               '    <outgoing>Flow_1e062xc</outgoing>\n' +
               '    </startEvent>\n' +
               '    <task id="process" name="已处理">\n' +
               '    <incoming>Flow_1e062xc</incoming>\n' +
               '    <outgoing>Flow_0oa87no</outgoing>\n' +
               '    </task>\n' +
               '    <endEvent id="end" name="结束">\n' +
               '    <incoming>Flow_0oa87no</incoming>\n' +
               '    </endEvent>\n' +
               '    <sequenceFlow id="Flow_1e062xc" sourceRef="start" targetRef="process" />\n' +
               '    <sequenceFlow id="Flow_0oa87no" sourceRef="process" targetRef="end" />\n' +
               '</process>\n' +
               '<bpmndi:BPMNDiagram id="BPMNDiagram_servicerequest-rq">\n' +
               '    <bpmndi:BPMNPlane id="BPMNPlane_servicerequest-rq" bpmnElement="test">\n' +
               '    <bpmndi:BPMNEdge id="Flow_1e062xc_di" bpmnElement="Flow_1e062xc">\n' +
               '        <omgdi:waypoint x="178" y="90" />\n' +
               '        <omgdi:waypoint x="320" y="90" />\n' +
               '    </bpmndi:BPMNEdge>\n' +
               '    <bpmndi:BPMNEdge id="Flow_0oa87no_di" bpmnElement="Flow_0oa87no">\n' +
               '        <omgdi:waypoint x="420" y="90" />\n' +
               '        <omgdi:waypoint x="572" y="90" />\n' +
               '    </bpmndi:BPMNEdge>\n' +
               '    <bpmndi:BPMNShape id="Event_1259xu3_di" bpmnElement="start">\n' +
               '        <omgdc:Bounds x="142" y="72" width="36" height="36" />\n' +
               '        <bpmndi:BPMNLabel>\n' +
               '        <omgdc:Bounds x="149" y="115" width="22" height="14" />\n' +
               '        </bpmndi:BPMNLabel>\n' +
               '    </bpmndi:BPMNShape>\n' +
               '    <bpmndi:BPMNShape id="Activity_1cc3ji9_di" bpmnElement="process">\n' +
               '        <omgdc:Bounds x="320" y="50" width="100" height="80" />\n' +
               '    </bpmndi:BPMNShape>\n' +
               '    <bpmndi:BPMNShape id="Event_19jebbw_di" bpmnElement="end">\n' +
               '        <omgdc:Bounds x="572" y="72" width="36" height="36" />\n' +
               '        <bpmndi:BPMNLabel>\n' +
               '        <omgdc:Bounds x="579" y="115" width="22" height="14" />\n' +
               '        </bpmndi:BPMNLabel>\n' +
               '    </bpmndi:BPMNShape>\n' +
               '    </bpmndi:BPMNPlane>\n' +
               '</bpmndi:BPMNDiagram>\n' +
               '</definitions>';

        formData.append('file', new Blob([newXml], { type: 'text/xml' }), id + '.bpmn20.xml');

        return formData;
    }

    designClick(record) {
        this.$router.push(`/dashboard/work-order-flow/${record.processKey}`);
    }

    deployClick(record) {
        (this.service as any).deploy(record.id).then(res => {
            this.showMessageSuccess('部署成功');

            this.getList();
        });
    }

    /* 查看流程图 */
    preview(record) {
        this.startFullScreenLoading();
        (this.service as any).preview(record.id).then(res => {
            this.flowVisible = true;

            var parser = new DOMParser();

            this.flowSVG = parser.parseFromString(res, 'text/xml');

            this.flowWidth = this.flowSVG.children[0].width.baseVal.value + 200;
        }).finally(() => {
            let flowDiv = document.getElementById('flow');

            flowDiv.append(this.flowSVG.documentElement);

            this.stopFullScreenLoading();
        });
    }

    handleOk() {
        this.flowVisible = false;
    }

    handleCancel() {
        this.flowVisible = false;
    }
}
